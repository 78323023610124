import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {Field, reduxForm} from 'redux-form';

import Select from '../../components/Select';

import {genderList, trainingExTypeList} from '../../helpers';

class FormSearch extends React.Component {
  render() {
      const { handleSubmit } = this.props;

    return (
      <form className="offers-search" onSubmit={handleSubmit}>
          <Field
              name="gender"
              component={Select}
              list={genderList}
              label="Gender"
          />

          <Field
              name="trainingType"
              component={Select}
              list={trainingExTypeList}
              label="Workout type"
          />

          <div className="offers__controls">
              <div className="btn-group">
                  <button className="btn" type="submit">
                      Search
                  </button>
              </div>
          </div>
      </form>
    );
  }
}

export default compose(
    reduxForm({
        form: 'ex-search',
        enableReinitialize: true,
    }),
    connect(
        (state) => ({
        }),
        (dispatch, props) => ({
        }),
    ),
)(FormSearch);
