import moment from 'moment';

export const selectInitialValue = [
  { value: 'all', label: 'All' }
];

export const settings = {
  defaultTrainingType: 'homeWorkout',
  defaultGender: 'male',
  defaultPersonalPlanTimes: 7
};

export const trainingTypeList = [
  {value: 'personalPlan', label: 'Personal Plan'},
  {value: 'homeWorkout', label: 'Home Workout'}
];

export const trainingExTypeList = [
  {value: 'homeWorkout', label: 'Home Workout'}
];

export const genderList = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'}
];

export const personalPlanTimesList = [
  {value: 2, label: '2 days of week'},
  {value: 3, label: '3 days of week'},
  {value: 4, label: '4 days of week'},
  {value: 5, label: '5 days of week'},
  {value: 6, label: '6 days of week'},
  {value: 7, label: '7 days of week'},
];

export const levelsList = [
  {value: 'beginner', label: 'Beginner'},
  {value: 'middle', label: 'Middle'},
  {value: 'senior', label: 'Senior'}
];

export const categoriesList = [
  {value: 'Category.homeWorkout', label: 'Home workout'},
];

export const equipmentsList = [
    {value: 'no equipment', label: 'No Equipment'}
];

export const typesList = [
  {value: 'full_body', label: 'Full body'},
  {value: 'stomach', label: 'Stomach'},
  {value: 'chest', label: 'Chest'},
  {value: 'back', label: 'Back'},
  {value: 'buttocks', label: 'Buttocks'},
  {value: 'hands', label: 'Hands'},
  {value: 'legs', label: 'Legs'}
];

export const areaOfFocusList = [
  {value: 'full_body', label: 'Full body'},
  {value: 'stomach', label: 'Stomach'},
  {value: 'chest', label: 'Chest'},
  {value: 'back', label: 'Back'},
  {value: 'buttocks', label: 'Buttocks'},
  {value: 'hands', label: 'Hands'},
  {value: 'legs', label: 'Legs'}
];

export const initialEx = {
  id: null,
  name: "",
  photoURL: "",
  videoURL: "",
  category: "Category.homeWorkout",
  areaOfFocus: [],
  howToDo: [],
  commonMistakes: [],
};

export const setsTemplate = {
  name : "Set",
  repeats: 0,
  afterRest: 30,
  beforeRest: 0,
  exercises: []
};

export const setsExTemplate = {
  id: null,
  name: "",
  photoURL: "",
  videoURL: "",
  type: "duration",
  duration: 20,
  reps: 0,
  restTimeSec: 10
};

export const workoutTemplate = (id) => ({
  id: id,
  title: "",
  category: "Category.homeWorkout",
  types: ["full body"],
  level: "beginner",
  photoURL: "https://melt.fit/db/workout-images/man-beginner-fb-1.jpeg",
  calories: 0,
  duration: 0,
  equipment: ["no equipment"],
  sets: []
});

export const moveCardSort = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;

    while (k--) {
      arr.push(undefined);
    }
  }

  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

  return arr;
};

// ===== other

export const formatDate = () => {
  let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  return [day, month, year].join('-');
};

export const ranges = {
  today: [new Date(), new Date()],
  yesterday: [
    moment().add(-1, 'days').toDate(),
    moment().add(-1, 'days').toDate(),
  ],
  week: [
    moment().startOf('isoweek').toDate(),
    moment().endOf('isoweek').toDate(),
  ],
  previousWeek: [
    moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
    moment().subtract(1, 'weeks').endOf('isoWeek').toDate(),
  ],
  month: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
  previousMonth: [
    moment().subtract(1, 'months').startOf('month').toDate(),
    moment().subtract(1, 'months').endOf('month').toDate(),
  ],
};
