import * as actions from '../constants/actionTypes';
import { settings } from '../helpers';

const initialFilter = {
  trainingType: settings.defaultTrainingType,
  gender: settings.defaultGender,
};

export const getExercises = (filter = initialFilter) => async (dispatch, getState) => {
  const { trainingType, gender } = filter;

  try {
    dispatch(exercisesFetching());

    const response = await fetch(`https://melt.fitness/src/exercises/${trainingType}/${gender}.json?data=${Date.now()}`);
    const result = await response.json();

    if (result) {
      const data = {
        list: result,
        filter: {
          trainingType,
          gender
        }
      };

      dispatch(setExercises(data));

      return data;
    }

    dispatch(setExercises({}));

    return result;
  } catch (error) {
    dispatch(exercisesFailure(error));
  }
};

export const saveExercises = () => async (dispatch, getState) => {
  const state = getState();
  const dataJSON = new FormData();

  const exercisesFilter = state.exercises.filter;
  const { list } = state.form['exercises'].values;

  const filename = `exercises/homeWorkout/${exercisesFilter.gender}.json`;
  dataJSON.append("json", JSON.stringify(list));
  dataJSON.append("filename", filename);

  try {
    dispatch(exercisesFetching());

    await fetch("https://melt.fitness/src/process.php",{
      method: "POST",
      body: dataJSON
    });

    dispatch(getExercises(exercisesFilter));
  } catch (error) {
    dispatch(exercisesFailure(error));
  }
};

const exercisesFetching = (status) => ({
  type: actions.EXERCISES_FETCHING,
  status,
});

const exercisesFailure = (error) => ({
  type: actions.EXERCISES_FAILURE,
  error,
});

export const setExercises = (data) => ({
  type: actions.SET_EXERCISES,
  data,
});
