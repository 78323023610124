import React  from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';

import WorkoutCard from './WorkoutCard';

import { workoutTemplate } from '../../helpers';

const Workout = ({ fields }) => {
  return (
    <React.Fragment>
      <header className="form-offers__header">
        <div className="form-offers__controls">
          <h2 className="title">Workout</h2>
        </div>

        <button
          className="btn"
          type="button"
          onClick={() => fields.push(workoutTemplate(fields.length + 1))}
        >
          Add Workout
        </button>
      </header>

      <section className="form-offers__body offers__body_full">
          {fields.map((workout, index) => (
              <WorkoutCard
                  index={index}
                  id={workout}
                  key={index}
                  workout={fields.get(index)}
                  onRemove={() => fields.remove(index)}
              />
          ))}
      </section>
    </React.Fragment>
  );
};

class FormWorkout extends React.Component {
  render() {
    return (
      <form className="form-offers">
        <FieldArray
          name="list"
          component={Workout}
          rerenderOnEveryChange
        />
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'workout',
    enableReinitialize: true,
  }),
  connect(
    (state) => ({
      formWorkout: getFormValues('workout')(state),
    }),
    (dispatch, props) => ({
    }),
  ),
)(FormWorkout);
