import * as actions from '../constants/actionTypes';

const initialState = {
  fetching: true,
  filter: {},
  data: {
    list: []
  },
  error: null,
};

const exercises = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_EXERCISES:
      return {
        ...state,
        data: {
          list: action.data.list
        },
        filter: action.data.filter,
        fetching: false,
      };
    case actions.EXERCISES_FETCHING:
      return {
        ...state,
        fetching: typeof action.status === 'boolean' ? action.status : true,
      };
    case actions.EXERCISES_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
};

export default exercises;
