import React from 'react';
import { NavLink } from 'react-router-dom';

import './index.css';

class Nav extends React.Component {
  render() {
    const { showMenu } = this.props;

    return (
      <nav className={`nav ${showMenu ? 'nav_open' : ''}`}>
        <NavLink
          className="nav__link"
          activeClassName="nav__link_active"
          to="/workout"
          exact
        >
          Workout
        </NavLink>
        <NavLink
          className="nav__link"
          activeClassName="nav__link_active"
          to="/exercises"
          exact
        >
          Exercises
        </NavLink>
      </nav>
    );
  }
}

export default Nav;
