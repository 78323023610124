import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import App from './App';

import rootReducer from './reducers';

import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container);
const store = createStore(rootReducer, applyMiddleware(thunk));

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

serviceWorker.unregister();
