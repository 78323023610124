import React  from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, FieldArray, getFormValues, reduxForm } from 'redux-form';

import FormEx from './formEx';
import Modal from '../../components/Modal';

import { closeModal, showModal } from '../../actions/modal';

import { initialEx } from '../../helpers';

class FormExercises extends React.Component {
    state = {
        searchItem: '',
        activeEx: null,
    };

    handleInputChange(e) {
        const searchTerm = e.target.value;

        this.setState({
            searchItem: searchTerm
        });
    }

    onEditExercise(ex) {
        const { onShowModal } = this.props;

        this.setState(
            { activeEx: {...ex} },
            () => onShowModal('ex-detail'),
        );
    }

  render() {
    const { onShowModal, onCloseModal, handleChange, formEx: { list } } = this.props;
    const { searchItem, activeEx } = this.state;

    console.log('--- root-ex --- ', list);

    return (
        <React.Fragment>
      <form className="form-offers">
          <section className="apps__search">
              <input
                  type="search"
                  value={searchItem}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Exercises filter"
              />
          </section>

        <FieldArray
          name="list"
          component={({ fields }) => (
              <React.Fragment>
                  <header className="apps__header">
                      <h2 className="title">Exercises</h2>

                      <div className="apps__actions">
                          <button
                              className="btn"
                              onClick={() => onShowModal('ex-detail')}
                          >
                              Add Exercise
                          </button>
                      </div>
                  </header>

                  <section className="form-apps form-offers__body offers__body_full">
                      {console.log(fields) || fields.map((ex, index) => {
                          const exItem = fields.get(index);

                          if (exItem.name.indexOf(searchItem) === -1) {
                            return;
                          }

                          return (
                              <div className="exercise-item" key={index}>
                                  <div className="exercise-item__content">
                                      <img src={exItem.photoURL} alt="Img not found" className="exercises-list__item_logo" />

                                      {exItem.name}
                                  </div>

                                  <div className="apps__icons">
                              <span
                                  className="icon icon_edit"
                                  onClick={() => {
                                      console.log('[onEditExercise]', ex, index);

                                      this.onEditExercise(exItem);
                                  }}
                              />

                                      <span
                                          className="icon icon_remove"
                                          onClick={() => {
                                              console.log('[onRemoveExercise]', fields.length, ex, index);

                                              fields.remove(index);
                                          }}
                                      />
                                  </div>
                              </div>
                          );
                      })}
                  </section>
              </React.Fragment>
          )}
          rerenderOnEveryChange
        />
      </form>

      <Modal
          title="Exercise Editor"
          name="ex-detail"
          onClose={() => {
              onCloseModal();

              this.setState({ activeEx: null });
          }}
      >
          <FormEx
              onSubmit={(data) => {
                  if (activeEx) {
                      this.setState(
                          {activeEx: null},
                          () => {
                              handleChange('exercises', 'list', list.map(item => item.id === data.id ? data : item));
                          },
                      );
                  } else {
                      handleChange('exercises', 'list', [...list, data]);
                  }

                  onCloseModal();
              }}
              initialValues={activeEx || initialEx}
              actionName={activeEx ? 'Update' : 'Create'}
          />
      </Modal>
        </React.Fragment>
    );
  }
}

export default compose(
  reduxForm({
    form: 'exercises',
    enableReinitialize: true,
  }),
  connect(
    (state) => ({
      formEx: getFormValues('exercises')(state),
    }),
    (dispatch, props) => ({
        handleChange: (form, name, value) => dispatch(change(form, name, value)),
        onShowModal: (name) => dispatch(showModal(name)),
        onCloseModal: () => dispatch(closeModal()),
    }),
  ),
)(FormExercises);
