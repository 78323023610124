import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import FormSearch from './formSearch';
import FormOffers from './formOffers';
import FormEx from './formEx';

import { getWorkouts, saveWorkouts } from '../../actions/workout';
import { getExercises } from "../../actions/exercises";
import { closeModal } from '../../actions/modal';

import { settings } from '../../helpers';

import './index.css';

class Offers extends React.Component {
    componentDidMount() {
        this.handleData({
            trainingType: settings.defaultTrainingType,
            gender: settings.defaultGender,
            personalPlanTimes: settings.defaultPersonalPlanTimes
        });
    }

    handleData(filter) {
        const { onGetWorkouts } = this.props;

        onGetWorkouts(filter);
    }

  render() {
    const {
      workout: { fetching, data, setsEx, filter },
      onGetWorkouts,
        onSaveWorkouts,
      onCloseModal,
      onChange
    } = this.props;

    return (
      <section className="offers">
        <section className="offers__search">
          <FormSearch
            onSubmit={(data) => onGetWorkouts(data)}
            initialValues={filter}
          />

          <div className="offers__controls">
            <div className="btn-group">
              <button
                  className="btn btn_info"
                  type="button"
                  onClick={() => onSaveWorkouts()}>
                Save
              </button>
            </div>
          </div>
        </section>

        {fetching ? <Loader /> : <FormOffers initialValues={{ ...data }} />}

        <Modal
            title="Exercise Editor"
            name="workout-ex"
            onClose={() => onCloseModal()}
        >
          <FormEx
              onSubmit={() => {
                onChange('workout', `list[${setsEx.index}].sets[${setsEx.i}].exercises`, setsEx.list);
                onCloseModal();
              }}
              actionName="Save"
          />
        </Modal>
      </section>
    );
  }
}

export default connect(
  (state) => ({
    workout: state.workout,
  }),
  (dispatch, props) => ({
    onChange: (form, name, value) => dispatch(change(form, name, value)),
      onGetWorkouts: (data) => dispatch(getWorkouts(data)),
      onSaveWorkouts: () => dispatch(saveWorkouts()),
      onGetExercises: (data) => dispatch(getExercises(data)),
    onCloseModal: () => dispatch(closeModal()),
  }),
)(Offers);
