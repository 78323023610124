import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import modal from './modal';
import exercises from './exercises';
import workout from './workout';

export default combineReducers({
  form,
  auth,
  modal,
  exercises,
  workout,
});
