import React, {Component} from 'react';
import {Field} from "redux-form";

export default class CheckboxGroup extends Component {

    field = ({input, meta, options}) => {

        const {name, onChange} = input;
        const {touched, error} = meta;
        const inputValue = input.value;

        const checkboxes = options.map(({label, value}, index) => {
            const handleChange = (event) => {
                const arr = [...inputValue];
                if (event.target.checked) {
                    arr.push(value);
                }
                else {
                    arr.splice(arr.indexOf(value), 1);
                }
                return onChange(arr);
            };

            const checked = inputValue.includes(value);

            return (
                <div className={`field field_checkbox field_checkbox_custom ${checked ? 'fields_checkbox__checked' : ''}`} key={index}>
                    <label key={`checkbox-${index}`} className="label label_custom">
                        <input type="checkbox" name={name} value={value} checked={checked} onChange={handleChange} className="input input_hidden" />
                        <span>{label}</span>
                    </label>
                </div>
            );
        });

        return (
            <div>
                <div>{checkboxes}</div>
                {touched && error && <p className="error">{error}</p>}
            </div>
        );
    };

    render() {
        const {
            input: { name }
        } = this.props;

        return <Field {...this.props} name={name} type="checkbox" component={this.field} />;
    }
}
