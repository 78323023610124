import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';

import Select from '../../components/Select';

import { trainingTypeList, genderList, personalPlanTimesList } from '../../helpers';

class FormSearch extends React.Component {
  render() {
      const {
          handleSubmit,
          formSearch: {
              trainingType
          }
      } = this.props;

    return (
      <form className="offers-search" onSubmit={handleSubmit}>
          <Field
              name="gender"
              component={Select}
              list={genderList}
              label="Gender"
          />

          <Field
              name="trainingType"
              component={Select}
              list={trainingTypeList}
              label="Workout type"
          />

          {
              trainingType === 'personalPlan' && (
                  <Field
                      name="personalPlanTimes"
                      component={Select}
                      list={personalPlanTimesList}
                      label="Frequency"
                  />
              )
          }

          <div className="offers__controls">
              <div className="btn-group">
                  <button className="btn" type="submit">
                      Search
                  </button>
              </div>
          </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'workout-search',
    enableReinitialize: true,
  }),
  connect(
    (state) => ({
        formSearch: getFormValues('workout-search')(state),
    }),
    (dispatch, props) => ({
    }),
  ),
)(FormSearch);
