import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import { changeSets } from '../../actions/workout';

class FormEx extends React.Component {
    state = {
        searchItem: '',
    };

    handleInputChange(e) {
        const searchTerm = e.target.value;

        this.setState({
            searchItem: searchTerm
        })
    }

    handleChange(isActiveEx, ex) {
        const { onChangeSet, formSets } = this.props;

        if (isActiveEx) {
            onChangeSet({
                list: [
                    ...formSets.list.filter(item => item.id !== ex.id)
                ]
            });
        } else {
            onChangeSet({
                list: [
                    ...formSets.list,
                    {
                        id: ex.id,
                        name: ex.name,
                        duration: 20,
                        reps: 0,
                        restTimeSec: 10,
                        type: "duration",
                        photoUrl: ex.photoUrl,
                        videoUrl: ex.videoUrl,
                    }
                ]
            });
        }
    }

  render() {
    const {
      handleSubmit,
      submitting,
      actionName,
      formSets,
        exercises: {
          data: { list }
      }
    } = this.props;
    const { searchItem } = this.state;

    return (
      <form onSubmit={handleSubmit} className="form form-ex">
          <div className="form-ex__search">
              <input
                  type="search"
                  value={searchItem}
                  onChange={(e) => this.handleInputChange(e)}
                  placeholder="Search Exercise..."
              />

              <button className="btn" type="submit" disabled={submitting}>
                  {actionName}
              </button>
          </div>

          <div className="form-ex__body">
              {
                  list.filter((item) => {
                      if (searchItem && searchItem.length)  {
                          return item.name.toLowerCase().indexOf(searchItem.toLowerCase()) !== -1;
                      }

                      return true;
                  }).map((ex, exIndex) => {
                      const isActiveEx = formSets.list.find((item) => item.id === ex.id);

                      return (
                          <div
                              key={exIndex}
                              className={`form-ex__item ${isActiveEx ? 'ex__item_active' : ''}`}
                              onClick={() => this.handleChange(isActiveEx, ex)}
                          >
                              <img src={ex.photoUrl} alt="Img not found" className='ex__item-img' />
                              <b className='ex__item-str'>{ex.id}</b>
                              <p className='ex__item-str'>{ex.name}</p>
                          </div>
                      );
                  })
              }
          </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'workout-ex',
    enableReinitialize: true,
  }),
  connect(
      (state) => ({
        formSets: state.workout.setsEx,
        exercises: state.exercises,
      }),
      (dispatch, props) => ({
          onChange: (form, name, value) => dispatch(change(form, name, value)),
          onChangeSet: (currentSet) => dispatch(changeSets(currentSet)),
      })
  )
)(FormEx);
