import * as firebase from 'firebase';

/*
const config = {
  apiKey: 'AIzaSyB27mY5qdAeKjB43yaYyNApsMvqoskY6tU',
  authDomain: 'pdl-profit.firebaseapp.com',
  databaseURL: 'https://pdl-profit.firebaseio.com',
  projectId: 'pdl-profit',
  storageBucket: 'pdl-profit.appspot.com',
  messagingSenderId: '830575924959',
  appId: '1:830575924959:web:f2a2707b835742c3e37b6a',
};
*/

const config = {
  apiKey: "AIzaSyAQGz4i505wGIPhWjI3PMMTboTgo4s8CPs",
  authDomain: "melt-381a1.firebaseapp.com",
  projectId: "melt-381a1",
  storageBucket: "melt-381a1.appspot.com",
  messagingSenderId: "327204680808",
  appId: "1:327204680808:web:331bcaa4e48a2c6281b7dd",
  measurementId: "G-PVM68Z4HRE"
};

firebase.initializeApp(config);

// const db = firebase.firestore();

// export default db;

export default firebase;
