import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Nav from '../Nav';

import { logout } from '../../actions/auth';

import './index.css';

class Header extends React.Component {
  state = {
    showMenu: false,
  };

  handleMenu() {
    const { showMenu } = this.state;

    this.setState({
      showMenu: !showMenu,
    });
  }

  render() {
    const { isAuth, onLogout } = this.props;
    const { showMenu } = this.state;

    return (
      <header className="header">
        <div className="header__container container">
          <div className="header__nav">
            <h1 className="logo">Melt</h1>

            {isAuth && <Nav showMenu={showMenu} />}
          </div>

          <div className="header__controls">

            {isAuth && (
              <span
                className="icon icon_logout icon_large"
                onClick={onLogout}
              />
            )}

            <span
              className="icon icon_layer icon_large"
              onClick={() => this.handleMenu()}
            />
          </div>
        </div>
      </header>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      isAuth: state.auth.uid,
    }),
    (dispatch, props) => ({
      onLogout: () => dispatch(logout()),
    }),
  ),
)(Header);
