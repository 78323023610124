import * as actions from '../constants/actionTypes';

const initialState = {
  fetching: true,
  filter: {},
  data: {
    list: [],
  },
  setsEx: {
    list: [],
    index: 0,
    i: 0
  },
  error: null,
};

const workout = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_WORKOUT:
      return {
        ...state,
        data: {
          list: action.data.list
        },
        filter: action.data.filter,
        fetching: false,
      };
    case actions.CHANGE_SET:
      return {
        ...state,
        setsEx: {
          ...state.setsEx,
          ...action.setsEx
        },
      };
    case actions.WORKOUTS_FETCHING:
      return {
        ...state,
        fetching: typeof action.status === 'boolean' ? action.status : true,
      };
    case actions.WORKOUTS_FAILURE:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    default:
      return state;
  }
};

export default workout;
