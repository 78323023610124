import * as actions from '../constants/actionTypes';
import { settings } from '../helpers';

import { getExercises } from './exercises';

const initialFilter = {
  trainingType: settings.defaultTrainingType,
  gender: settings.defaultGender,
  personalPlanTimes: settings.defaultPersonalPlanTimes
};

export const getWorkouts = (filter = initialFilter) => async (dispatch, getState) => {
  const { trainingType, gender, personalPlanTimes } = filter;

  dispatch(getExercises({
    ...filter,
    trainingType: 'homeWorkout'
  }));

  try {
    dispatch(workoutFetching());

    const link = trainingType === 'personalPlan'
        ? `https://melt.fitness/src/workout/${trainingType}/${personalPlanTimes}/${gender}.json?data=${Date.now()}`
        : `https://melt.fitness/src/workout/${trainingType}/${gender}.json?data=${Date.now()}`;

    const response = await fetch(link);
    const result = await response.json();

    if (result) {
      const data = {
        list: result,
        filter: {
          trainingType,
          personalPlanTimes,
          gender
        }
      };

      dispatch(setWorkout(data));

      return data;
    }

    dispatch(setWorkout({}));

    return {};
  } catch (error) {
    dispatch(workoutFailure(error));
  }
};

export const saveWorkouts = () => async (dispatch, getState) => {
  const state = getState();
  const dataJSON = new FormData();

  const { list } = state.form['workout'].values;
  const workoutFilter = state.workout.filter;

  const filename = workoutFilter.trainingType === 'homeWorkout'
      ? `workout/${workoutFilter.trainingType}/${workoutFilter.gender}.json`
      : `workout/${workoutFilter.trainingType}/${workoutFilter.personalPlanTimes}/${workoutFilter.gender}.json`;
  dataJSON.append("json", JSON.stringify(list));
  dataJSON.append("filename", filename);

  try {
    dispatch(workoutFetching());

    await fetch("https://melt.fitness/src/process.php",{
      method: "POST",
      body: dataJSON
    });

    dispatch(getWorkouts(workoutFilter));
  } catch (error) {
    dispatch(workoutFailure(error));
  }
};

export const changeSets = (currentSet) => async (dispatch, getState) => {
  dispatch(setSets(currentSet));
};

const workoutFetching = (status) => ({
  type: actions.WORKOUTS_FETCHING,
  status,
});

const workoutFailure = (error) => ({
  type: actions.WORKOUTS_FAILURE,
  error,
});

export const setWorkout = (data) => ({
  type: actions.SET_WORKOUT,
  data,
});

export const setSets = (setsEx) => ({
  type: actions.CHANGE_SET,
  setsEx,
});
