import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, change, getFormValues } from 'redux-form';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Input from '../../components/Input';
import Select from '../../components/Select';
import CheckboxGroup from '../../components/CheckboxGroup';
import { ExCard } from './ExCard';

import { changeSets } from '../../actions/workout';
import { showModal } from '../../actions/modal';

import {
  equipmentsList,
  levelsList,
  typesList,
  categoriesList,
  setsTemplate,
  moveCardSort
} from '../../helpers';

const WorkoutCard = ({
  id,
  index,
  workout,
  onRemove,
  onChange,
  formWorkout,
  onShowModal,
  onChangeSet
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleRemoveWorkout = () => {
    const isReady = window.confirm('Are you ready?');

    if (isReady) {
      onRemove();
    }
  };

  const handleRemoveSet = (i) => {
    const isReady = window.confirm('Are you ready?');

    if (isReady) {
      const newList = [];

      formWorkout.list[index].sets.forEach((item, indx) => {
        if (indx !== i) {
          newList.push(item)
        }
      })

      onChange('workout', `list[${index}].sets`, newList);
    }
  };

  const handleAddSet = () => {
    onChange('workout', `list[${index}].sets`, [...formWorkout.list[index].sets, setsTemplate]);
  };

  const handleAddEx = (i) => {
    onChangeSet({
      list: workout.sets[i].exercises,
      index,
      i
    });

    onShowModal('workout-ex');
  };

  const handleMoveCard = (setIndex, dragIndex, hoverIndex) => {
    onChange(
        'workout',
        `list[${index}].sets[${setIndex}].exercises`,
        moveCardSort(workout.sets[setIndex].exercises, dragIndex, hoverIndex)
    );
  };

  const handleRemoveEx = (setIndex, exIndex) => {
    const list = workout
        .sets[setIndex]
        .exercises.filter((item, index) => index !== exIndex);

    onChange('workout', `list[${index}].sets[${setIndex}].exercises`, list);
  };

  const handleReplaceEx = (i) => {
    onChangeSet({
      list: workout.sets[i].exercises,
      index,
      i
    });

    onShowModal('workout-ex');
  };

  return (
    <article
      id={id} key={index} className={`offer ${showDetails ? 'offer_details' : ''} ${
          workout.isHidden ? 'offer_hidden' : ''
      }`}
    >
      <div className="offer__general">
        <div className="offer__logo">
          <div className="offer__company">
            <img src={workout.photoURL} alt="Img not found" />

            <Field name={`list[${index}].photoURL`} component={Input} />
          </div>
        </div>

        <div className="offer__info">
          <ul className="data">
            <li className="data__item data__item_id">
              <Field name={`list[${index}].id`} label="ID" component={Input} />
            </li>

            <li className="data__item data__item_title">
              <Field name={`list[${index}].title`} label="Title" component={Input} />
            </li>

            <li className="data__item data__item_cal">
              <Field name={`list[${index}].calories`} label="Calories" component={Input} />
            </li>

            <li className="data__item data__item_duration">
              <Field name={`list[${index}].duration`} label="Duration" component={Input} />
            </li>

            <li className="data__item data__item_select">
              <Field
                  name={`list[${index}].category`}
                  component={Select}
                  list={categoriesList}
                  label="Category"
              />
            </li>

            <li className="data__item data__item_select">
              <Field
                  name={`list[${index}].level`}
                  component={Select}
                  list={levelsList}
                  label="Level"
              />
            </li>

            <li className="data__item data__item_select">
              <Field component={CheckboxGroup} name={`list[${index}].equipment`} options={equipmentsList} />
            </li>

            <li className="data__item data__item_select">
              <Field component={CheckboxGroup} name={`list[${index}].types`} options={typesList} />
            </li>
          </ul>
        </div>

        <div className="offer__conditions">
          <div className="offer__actions">
            <div className="icon-wrap" onClick={() => handleRemoveWorkout()}>
              <span className="icon icon_remove" />
            </div>

            <div className="icon-wrap">
              <Field
                name={`list[${index}].isHidden`}
                type="checkbox"
                component={Input}
                custom
              >
                <span className="icon icon_visibility" />
              </Field>
            </div>
          </div>
        </div>
      </div>

      <React.Fragment>
        <div
            className="offer__options"
            onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? 'Hide Sets & Exercises' : 'Show Sets & Exercises'}
        </div>

        <div className="btn-set" onClick={() => handleAddSet()}>
          <button className="btn btn_info" type="button">Add Set</button>
        </div>
      </React.Fragment>

      {
        showDetails && (
              <div className="offer__additional">
                {workout.sets.map((item, i) => (
                    <div className="exercises" key={`${index}-${i}`}>
                      <div className="exercises__header">
                        <ul className="data">
                          <li className="data__item">
                            <Field
                                name={`list[${index}].sets[${i}].name`}
                                component={Input}
                                label="Set"
                            />
                          </li>

                          <li className="data__item">
                            <Field
                                name={`list[${index}].sets[${i}].repeats`}
                                component={Input}
                                label="Repeats"
                            />
                          </li>

                          <li className="data__item">
                            <Field
                                name={`list[${index}].sets[${i}].rest`}
                                component={Input}
                                label="Rest"
                            />
                          </li>

                          <div className="icon-wrap" onClick={() => handleRemoveSet(i)}>
                            <span className="icon icon_remove" />
                          </div>
                        </ul>
                      </div>

                      <div className="exercises-list">
                        <DndProvider backend={HTML5Backend}>
                        {
                          workout.sets[i].exercises
                              && (
                                workout.sets[i].exercises.map((ex, y) => (
                                    <ExCard
                                        index={y}
                                        i={i}
                                        y={y}
                                        id={y}
                                        key={y}
                                        listIndex={index}
                                        moveCard={(dragIndex, hoverIndex) => handleMoveCard(i, dragIndex, hoverIndex)}
                                        removeEx={() => handleRemoveEx(i, y)}
                                        replaceEx={() => handleReplaceEx(i)}
                                    />
                                ))
                            )
                        }
                        </DndProvider>
                      </div>

                      <div className="btn-set btn-set_wrapper" onClick={() => handleAddEx(i)}>
                        <button className="btn btn_info" type="button">Add Exercise</button>
                      </div>
                    </div>
                ))}
              </div>
          )
      }
    </article>
  );
};

export default connect(
    (state) => ({
      formWorkout: getFormValues('workout')(state),
    }),
    (dispatch, props) => ({
      onChange: (form, name, value) => dispatch(change(form, name, value)),
      onShowModal: (name) => dispatch(showModal(name)),
      onChangeSet: (currentSet) => dispatch(changeSets(currentSet)),
    }),
)(WorkoutCard);
