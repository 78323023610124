import * as actions from '../constants/actionTypes';

export const showModal = (data) => {
  document.body.style.overflow = "hidden"

  return {
    type: actions.SHOW_MODAL,
    data,
  };
};

export const closeModal = () => {
  document.body.style.overflow = "auto"

  return {
    type: actions.CLOSE_MODAL,
  };
};
