import React from 'react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modal';

import './index.css';

class Modal extends React.Component {
  render() {
    const {
      name,
      openModal,
      title,
      children,
      onCloseModal,
      onClose,
    } = this.props;

    return (
      openModal === name && (
        <div className="modal-overlay">
          <div className={`modal ${name}`}>
            <div className="modal__header">
              <h2 className="title">{title}</h2>

              <span
                className="icon icon_remove"
                onClick={() => (onClose ? onClose() : onCloseModal())}
              />
            </div>

            <div className="modal__body">{children}</div>
          </div>
        </div>
      )
    );
  }
}

export default connect(
  (state) => ({
    openModal: state.modal.openModal,
  }),
  (dispatch, props) => ({
    onCloseModal: () => dispatch(closeModal()),
  }),
)(Modal);
