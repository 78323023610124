import React from 'react';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';

import FormSearch from "./formSearch";
import FormExercises from './formExGlobal';

import { getExercises, saveExercises } from '../../actions/exercises';

import { settings } from '../../helpers';

import './index.css';

class Exercises extends React.Component {
  state = {
      currentProps: {
          trainingType: settings.defaultTrainingType,
          gender: settings.defaultGender,
      }
  };

  componentDidMount() {
    const { onGetExercises } = this.props;

    onGetExercises({
        trainingType: settings.defaultTrainingType,
        gender: settings.defaultGender,
    });
  }

  render() {
    const {
      exercises: { fetching, data, filter },
      onGetExercises,
      onSaveExercises,
    } = this.props;

    return fetching ? (
      <Loader />
    ) : (
      <section className="apps">
          <section className="apps__search-header">
              <FormSearch
                  onSubmit={(data) => {
                      onGetExercises(data);
                  }}
                  initialValues={filter}
              />

              <div className="apps__search-header__controls">
                  <div className="btn-group">
                      <button
                          className="btn btn_info"
                          type="button"
                          onClick={() => onSaveExercises()}>
                          Save
                      </button>
                  </div>
              </div>
          </section>

          {fetching ? <Loader /> : <FormExercises initialValues={{ ...data }} />}
      </section>
    );
  }
}

export default connect(
  (state) => ({
    exercises: state.exercises,
  }),
  (dispatch, props) => ({
      onGetExercises: (data) => dispatch(getExercises(data)),
      onSaveExercises: () => dispatch(saveExercises())
  }),
)(Exercises);
