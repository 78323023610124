import React, { useRef } from 'react';
import { Field } from 'redux-form';
import { useDrag, useDrop } from 'react-dnd';

import Input from '../../components/Input';

const style = {
  cursor: 'move',
}

export const ExCard = ({ id, i, y, index, moveCard, removeEx, replaceEx, listIndex }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const handleRemoveEx = (i, y) => {
    removeEx(y);
  };

  const handleReplaceEx = (i, y) => {
    replaceEx(y);
  };

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
      <div className="exercises-list__item" ref={ref} data-handler-id={handlerId} style={{ ...style, opacity }}>
        <img src={`list[${listIndex}].sets[${i}].exercises[${y}].photoUrl`} alt="Img not found" className="exercises-list__item_logo" />

        <div className="data__item-wrapper">
          <li className="data__item data__item_name">
            <Field
                name={`list[${listIndex}].sets[${i}].exercises[${y}].name`}
                component={Input}
            />
          </li>

          <li className="data__item data__item_duration">
            <Field
                name={`list[${listIndex}].sets[${i}].exercises[${y}].duration`}
                component={Input}
            />
          </li>
        </div>

        <span className="icon icon_refresh" onClick={() => handleReplaceEx(i, y)}/>
        <span className="icon icon_remove" onClick={() => handleRemoveEx(i, y)} />
      </div>
  );
};
