// Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FETCHING = 'LOGIN_FETCHING';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Exercises
export const SET_EXERCISES = 'SET_EXERCISES';
export const EXERCISES_FETCHING = 'EXERCISES_FETCHING';
export const EXERCISES_FAILURE = 'EXERCISES_FAILURE';

// Workout
export const SET_WORKOUT = 'SET_WORKOUT';
export const CHANGE_SET = 'CHANGE_SET';
export const WORKOUTS_FETCHING = 'WORKOUTS_FETCHING';
export const WORKOUTS_FAILURE = 'WORKOUTS_FAILURE';
