import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {Field, reduxForm, getFormValues, change} from 'redux-form';

import Input from '../../components/Input';
import Select from '../../components/Select';
import CheckboxGroup from '../../components/CheckboxGroup';

import {areaOfFocusList, categoriesList} from '../../helpers';

class FormEx extends React.Component {
    handleRemoveSet(category, index) {
        const {formEx, onChange} = this.props;
        const newList = [];

        formEx[category].forEach((item, indx) => {
            if (indx !== index) {
                newList.push(item)
            }
        })

        onChange('ex-detail', category, newList);
    };

    handleAddSet(category) {
        const {onChange, formEx} = this.props;

        onChange('ex-detail', category, [...formEx[category], '']);
    };

  render() {
    const {
      handleSubmit,
      submitting,
      actionName,
      formEx
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form form-ex">
          <div className="form-ex__header">
              <div className="form-ex__photo-left">
                  <img src={formEx.photoURL} alt="Img not found" className="form-ex__logo"/>
              </div>
              <div className="form-ex__photo-right">
                  <div className="form-ex__field form-ex__field_id">
                    <Field name="id" component={Input} className="input" label="Id" disabled />
                  </div>

                  <div className="form-ex__field form-ex__field_name">
                    <Field name="name" component={Input} className="input" label="Name" />
                  </div>

                  <div className="form-ex__field">
                    <Field name="photoURL" component={Input} className="input" label="Photo URL" />
                  </div>

                  <div className="form-ex__field">
                    <Field name="videoURL" component={Input} className="input" label="Video URL" />
                  </div>

                  <div className="form-ex__field">
                      <Field
                          name="category"
                          component={Select}
                          list={categoriesList}
                          label="Category"
                          className="form-ex__field"
                      />
                  </div>
              </div>
          </div>

          <div className="form-ex__content">
              <div className="form-ex__field">
                  <h2 className="title">How to Do:</h2>
                  {
                      formEx.howToDo.map((item, indx) => (
                          <div className="form-ex__field-inner" key={indx}>
                              <Field
                                  name={`howToDo[${indx}]`}
                                  component={Input}
                                  className="Input"
                                  label={`Step: ${indx + 1}`}
                              />

                              <span
                                  className="icon icon_remove"
                                  onClick={() => this.handleRemoveSet('howToDo', indx)}
                              />
                          </div>
                      ))
                  }

                  <div
                      className="form-ex__btn btn-set"
                      onClick={() => this.handleAddSet('howToDo')}
                  >
                      <span className="icon icon_plus" />
                  </div>
              </div>

              <div className="form-ex__field">
                  <h2 className="title">Common Mistakes:</h2>
                  {
                      formEx.commonMistakes.map((item, indx) => (
                          <div className="form-ex__field-inner" key={indx}>
                              <Field
                                  name={`commonMistakes[${indx}]`}
                                  component={Input}
                                  className="Input"
                                  label={`Mistake: ${indx + 1}`}
                              />

                              <span
                                  className="icon icon_remove"
                                  onClick={() => this.handleRemoveSet('commonMistakes', indx)}
                              />
                          </div>
                      ))
                  }

                  <div
                      className="form-ex__btn btn-set"
                      onClick={() => this.handleAddSet('commonMistakes')}
                  >
                      <span className="icon icon_plus" />
                  </div>
              </div>
          </div>

          <div className="form-ex__area">
              <Field component={CheckboxGroup} name="areaOfFocus" options={areaOfFocusList} />
          </div>

        <div className="form__controls">
          <button className="btn" type="submit" disabled={submitting}>
            {actionName}
          </button>
        </div>
      </form>
    );
  }
}

export default compose(
  reduxForm({
    form: 'ex-detail',
    enableReinitialize: true,
  }),
  connect(
      (state) => ({
        formEx: getFormValues('ex-detail')(state),
      }),
      (dispatch, props) => ({
          onChange: (form, name, value) => dispatch(change(form, name, value)),
      })
  )
)(FormEx);
