import React from 'react';
import { HashRouter as Router, Switch, Redirect } from 'react-router-dom';

import Workout from './pages/Workout';
import Exercises from './pages/Exercises';
import Auth from './pages/Auth';

import Layout from './pages/Layout';
import PrivateRoute from './pages/Layout/privateRoute';
import GuestRoute from './pages/Layout/guestRoute';

import './assets/styles/app.css';

const App = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <PrivateRoute exact path="/workout" component={Workout} />
          <PrivateRoute exact path="/exercises" component={Exercises} />
          <GuestRoute exact path="/login" component={Auth} />
          <Redirect from="/" to="workout" />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
